import React, {useEffect} from 'react';
import { genBlock } from '@/utils/bem';
import bgGuanWang from '@/assets/images/OfficialWebsite/bg_guanwang.png'
import beianIcon from '@/assets/images/OfficialWebsite/beian_icon.png'
import './style.scss';

const { block } = genBlock('pg-official-website');

export const OfficialWebsite: React.FC = () => {
    useEffect(() => {
        document.title = "Folo"
    });

    return (
        <div>
            <div>
                <img className={block('bg_img')} src={bgGuanWang} alt="" />
            </div>
            <div className={block('footer')}>
                <div className={block('copyright')}>
                    ©2023 FoloTech Inc. All Rights Reserved.  &nbsp;&nbsp;&nbsp;
                    {/*<a target="_blank" rel="noreferrer"*/}
                    {/*   href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802039747">*/}
                    {/*    <img src={beianIcon} alt="" />*/}
                    {/*    京公网安备 11010802039747号*/}
                    {/*</a>*/}
                    <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
                       target="_blank"  rel="noreferrer" >京ICP备2022018596号-1</a>
                </div>
            </div>
        </div>
    );
};
