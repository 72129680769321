import React from 'react';
import {Routes, Route, HashRouter} from 'react-router-dom';
import { OfficialWebsite } from '@/pages/OfficialWebsite';
import { DownloadPage } from '@/pages/Download';

export const RootRouter: React.FC = () => {
  return (
    <HashRouter >
      <Routes>
        <Route index element={<OfficialWebsite />} />
        {/*<Route path="/payment" element={<PaymentPage />} />*/}
        {/*<Route path="/paymentSucceed" element={<PaymentSucceedPage />} />*/}
        {/*<Route path="/bxServiceBuy" element={<BXServiceBuyPage />} />*/}
        <Route path="/download" element={<DownloadPage />} />
      </Routes>
    </HashRouter>
  );
};
