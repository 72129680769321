import React, {useEffect, useState} from 'react';
import { genBlock } from '@/utils/bem';
import { Button, Affix } from 'antd';
import './style.scss';
import download from '../../assets/images/Download/download.png';
import browser from '../../assets/images/Download/browser.png';
const { block } = genBlock('pg-download');
export const DownloadPage: React.FC = () => {

  useEffect(() => {
    document.title = "Folo下载"
  });

  const [isMask, setIsMask] = useState(false);
  const [isTip, setisTip] = useState(false);
  
  const handleDownload = () => {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (u.toLowerCase().indexOf('micromessenger') > -1) {
      //微信环境
      setIsMask(true);
      setisTip(true);
    } else if (isAndroid) {
      //安卓环境
      window.open('https://cos.6tiantian.com/android_custom_apk/com.folo.foloStudy.apk', '_blank');
    } else if (isiOS) {
      //ios环境
      window.open(
        'https://apps.apple.com/cn/app/folo-%E4%B8%8E%E4%BC%98%E7%A7%80%E5%90%8C%E5%AD%A6%E7%BB%84%E5%9B%A2/id1622316498?l=en',
        '_blank'
      );
    } else {
      setIsMask(true);
      setisTip(true);
    }
  };
  
  return (
    <div className={block()}>
      {isMask && <div className={block('mask')}></div>}
      <img className={block('bgimg')} src={download} alt="" />
      <Affix offsetBottom={0}>
        <Button className={block('btn')} onClick={handleDownload}>
          立即下载
        </Button>
      </Affix>
      
      {isTip && (
        <div className={block('bgtip')}>
          <span className={block('tip')}>
            请点击右上角
            <img className={block('browser')} src={browser} alt="" />
            浏览器点开
          </span>
        </div>
      )}
    </div>
  );
};
